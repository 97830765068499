.EditJodit {
  position: relative;
}

.EditJodit .jodit-container:not(.jodit_inline) {
  border: 1px solid #f00;
  border-top-color: rgb(218, 218, 218);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(218, 218, 218);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(218, 218, 218);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(218, 218, 218);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.EditJodit .jodit-toolbar__box {
  position: absolute;
  bottom: 0;
  background-color: transparent;
}
