.EditJodit {
  position: relative;
}

.jodit-container jodit jodit_theme_summer jodit-wysiwyg_mode {
  min-height: 40px !important;
}

.EditJodit .jodit-container:not(.jodit_inline) {
  border-top-color: rgb(218, 218, 218);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(218, 218, 218);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(218, 218, 218);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.EditJodit .jodit-toolbar__box {
  position: absolute;
  bottom: 0;
  background-color: transparent;
  display: flex !important;
}

.jodit-ui-group .jodit-ui-group_size_middle {
  flex-wrap: unset !important;
  background-color: #f5f5f5;
}

.jodit-container:not(.jodit_inline) .jodit-wysiwyg {
  text-align: start;
}

.jodit-toolbar-button button {
  padding: 8px !important;
}

.EditJodit .jodit-placeholder {
  z-index: 0;
}

.EditJodit .jodit-status-bar {
  background-color: white;
  border-top: 1px solid transparent !important;
}

.EditJodit .jodit-workplace + .jodit-status-bar:not(:empty) {
  border-top: 1px solid transparent !important;
}

.EditJodit
  .jodit-status-bar
  .jodit-status-bar__item.jodit-status-bar__item-right {
  position: absolute;
  left: 75%;
  top: 85%;
}
